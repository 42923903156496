// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Actions
import { userRegister, userOAuthLogin } from '../../actions/authActions';

// Components
import RegisterForm from './RegisterForm';
import Meta from '../shared/Meta';

import styles from '../../../stylesheets/public/layouts/Register.module.postcss';

class Register extends Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = {
    };
  }

  onFormSubmit(model) {
    const data = _.merge({}, model);
    data.catering_user = false;
    this.props.userRegister(data).then((response) => {
      if (response.status === 'success') {
        setTimeout(() => {
          this.props.history.push('/vendor/truck/new');
        }, 100);
      }
    });
  }

  validateForm(values) {
    const { password, password_confirmation } = values;
    const errors = {};
    if (password_confirmation &&
        password_confirmation !== password) {
      errors.password_confirmation = 'Passwords must match';
    }
    return errors;
  }

  render() {
    const { auth } = this.props;

    return (
      <div className={styles.Register + " u-textCenter u-blockCenter u-limitWidth35 u-mb3 u-mt3"}>
        <Meta noindex />
        <h1 className="u-mb3">Create your account</h1>
        <div className="Box Box--white">
          <div>
            {auth.userRegisterFailed &&
              <div className="FormMessages FormMessages--error">
                <span className="icon icon-warning" />
                <h4>
                  {auth.message}
                </h4>
              </div>
            }

            <div className="u-mb1 u-textLeft">
              <RegisterForm
                validate={this.validateForm}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  catering_user: false
                }}
              />
            </div>
            <hr className={styles.OrRule} />
            <div className="u-mb1">
              <div className="u-text-sm u-blockCenter u-textCenter u-limitWidth25 u-mb1">
                By clicking Sign Up with Facebook or Sign Up with Google, you agree to the <Link to='/terms-and-conditions'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.
              </div>
              <button
                className={"Button Button--outlined u-w100 " + styles.Button__facebook}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'facebook',
                    params: {
                      catering_user: false
                    }
                  }, this.props.history);
                }}
              >
                <img src='/static/images/icons/facebook-logo-secondary.png'/>
                <span>Continue with Facebook</span>
              </button>

              <button
                className={`Button Button--outlined u-w100 ${styles.Button__google} u-ml0 u-mt1`}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'google',
                    params: {
                      catering_user: false
                    }
                  }, this.props.history);
                }}
              >
                <img src='/static/images/icons/google.svg'/>
                <span>Sign up with Google</span>
              </button>

              <button
                className={"Button u-w100 u-mt1 " + styles.Button__apple}
                onClick={() => {
                  this.props.userOAuthLogin({
                    provider: 'apple',
                    params: {
                      needs_user_type: true
                    }
                  }, this.props.history);
                }}
              >
                <img src="/static/images/appleid_button_signup.png" height="48"/>
              </button>
            </div>
          </div>
        </div>
        <div className="u-mt2">
          <small>Already have an account? <Link to="/login">Log in</Link>.</small>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  route: PropTypes.object,
  userRegister: PropTypes.func.isRequired,
  userOAuthLogin: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userRegister: (data) => {
      return dispatch(userRegister(data));
    },
    userOAuthLogin: (provider, history) => {
      dispatch(userOAuthLogin(provider, history));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);

