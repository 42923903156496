export const required = (value) => {
  return value ? undefined : 'Required';
};

export const selectMultiRequired = (value) => {
  let isDefined = (value !== undefined);
  return !isDefined || isDefined && value.length > 0 ? undefined : 'Required';
};

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined;
};

export const bidAmount = (value) => {
  return value && !/^[0-9]{3,5}$/i.test(value) ?
  'Only numbers are allowed. You may enter between 3 and 5 numbers' : undefined;
};
