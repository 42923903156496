import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Input = (props) => {
  const { label, input, type, hint, half, inline, placeholder, labelClassName, icon,
          inputClassName, className, required, checkboxLabel, darkIcon,
          meta: { error, warning, touched } } = props;
  return (
    <div
      className={
        cn({
          FormRow: true,
          'FormRow--half': half,
          'FormRow--inline': inline,
          'FormRow--checkboxLabel': checkboxLabel,
        }, className)
      }
    >
      {label &&
        <label
          className={cn({ FormLabel: true, 'FormLabel--required': required }, labelClassName)}
          htmlFor={input.name}
        >
          {label}
        </label>
      }

      {icon &&
        <div className={cn("TextInput-iconContainer", {
          'TextInput-iconContainer--dark': darkIcon,
        })}>
          <div className="TextInput-icon">{icon}</div>
          <input
            {...input}
            id={input.name}
            placeholder={placeholder}
            className={cn("TextInput", inputClassName)}
            type={type}
          />
        </div>
      }

      {!icon &&
        <input
          {...input}
          id={input.name}
          placeholder={placeholder}
          className={cn("TextInput", inputClassName)}
          type={type}
        />
      }

      {checkboxLabel &&
        <label className="checkboxLabel" htmlFor={input.name}>{checkboxLabel}</label>
      }

      {hint &&
        <div className="hint">
          {hint}
        </div>
      }

      {touched && (error || warning) &&
        <div className="ErrorExplanation">
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      }
    </div>
  );
};

Input.propTypes = {
  input: PropTypes.object,
  error: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  hint: PropTypes.string,
  touched: PropTypes.bool,
  half: PropTypes.bool,
  required: PropTypes.bool,
  warning: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object
};

Input.defaultProps = {
  label: null
}

export default Input;
