// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

// Components
import Input from '../form/Input';
import Loader from '../shared/Loader';

// Misc
import * as v from '../../modules/validators';

class RegisterForm extends Component {

  render() {
    const { handleSubmit, valid, submitting } = this.props;

    return (
      <form
        className="FlexForm"
        onSubmit={handleSubmit}
      >
        <div className="Fieldset">
          <Field
            label="First name"
            name="first_name"
            component={Input}
            half
            type="text"
            required
            validate={[v.required]}
          />

          <Field
            label="Last name"
            name="last_name"
            component={Input}
            half
            type="text"
            required
            validate={[v.required]}
          />

          <Field
            label="Phone"
            name="phone"
            component={Input}
            type="tel"
            required
            validate={[v.required]}
          />

          <Field
            label="Email"
            name="email"
            component={Input}
            type="email"
            required
            validate={[v.required, v.email]}
          />

          <Field
            label="Password"
            name="password"
            component={Input}
            type="password"
            required
            validate={[v.required]}
          />

          <Field
            label="Confirm Password"
            name="password_confirmation"
            component={Input}
            type="password"
            required
            validate={[v.required]}
          />

          <div className="FormRow">
            <div className="u-text-sm u-blockCenter u-textCenter u-limitWidth25">
              By clicking Create Account, you agree to the <Link to='/terms-and-conditions'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.
            </div>
            <Loader entities="user" actions="update">
              <button
                className="Button u-w100 u-mt1"
                type="submit"
                disabled={!valid || submitting}
              >
                <span className="u-w100 u-textCenter">Create Account</span>
              </button>
            </Loader>
          </div>
        </div>
      </form>
    );
  }
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  valid: PropTypes.bool,
  submitting: PropTypes.bool
};

export default reduxForm({ form: 'register' })(RegisterForm);

